var LicenseStatus;

(function (LicenseStatus) {
  LicenseStatus["NotFound"] = "NotFound";
  LicenseStatus["Invalid"] = "Invalid";
  LicenseStatus["Expired"] = "Expired";
  LicenseStatus["Valid"] = "Valid";
  LicenseStatus["OutOfScope"] = "OutOfScope";
})(LicenseStatus || (LicenseStatus = {}));

export { LicenseStatus };